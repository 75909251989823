.container {
  padding: 0;
  margin-bottom: 15rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  .subTitle {
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  .description {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
  }

  .submitButton {
    margin: 1rem 0;
  }

  .errorAlert {
    margin: 1rem 0;
  }

  .otpInput {
    height: 2.5rem;
    width: 2.5rem !important;
    border-style: solid;
    border-width: 1px;
    border-color: #bdbdbd;
    margin: 0 0.2rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;

    &:hover {
      border-color: #000;
    }
  }

  .otpInputOnFocus {
    border-color: #ff5252;
    outline-color: #ff5252;
  }
}
