.foodCourtsContainer {
  margin-bottom: 15rem;
}

.foodCourt {
  background-color: #eceff1;
  padding: 0 1rem;
  margin: 1rem 0;
  border-radius: 0.4rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &.selected {
    background-color: #c8e6c9;
    border: 1px solid #4caf50;

    &:hover {
      background-color: #c8e6c9;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #e0e0e0;
  }
}
