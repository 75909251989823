.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .image {
    height: 15rem;
  }

  .header {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
  }

  .message {
    margin-top: 0.5rem;
    text-align: center;
  }
}
