body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 500;
}

p,
span {
  font-weight: 400;
}
