.container {
  padding: 0 0 1rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  .subTitle {
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  .description {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
  }

  .input {
    margin: 0.5rem 0;
  }

  .submitButton {
    margin: 1rem 0;
  }

  .errorAlert {
    margin: 1rem 0;
  }

  .disclaimer {
    font-size: 0.7rem;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }
}
