.vendor {
  margin-bottom: 10rem;

  .informationContainer {
    .closedStatus {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #bdbdbd;
      margin: 2rem 0;
      text-align: center;
      border-radius: 0.2rem;
    }
  }
}

.vendorInformation {
  > .name {
    font-size: 1.3rem;
    font-weight: 500;
  }

  > .description {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

.menuSlider {
  background-color: #fafafa;
  padding-top: 0.5rem;
  display: flex;

  .menuButton {
    display: inline;
    padding: 0 0.5rem 0.5rem 0.5rem;

    span {
      font-size: 0.9rem;
    }

    &:hover {
      cursor: pointer;
      border-bottom: 2px solid #bdbdbd;
    }

    &.selected {
      border-bottom: 2px solid #000;

      span {
        font-weight: 700;
      }
    }
  }
}

.category {
  > .name {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 1rem 0;
  }
}

.dishCard {
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 0;

  .informationContainer {
    flex: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .left {
      > .name {
        margin: 0 0 0.3rem 0;
        font-weight: 500;
        font-size: 1rem;
      }

      > .description {
        margin: 0 0 0.3rem 0;
        font-size: 0.8rem;
      }

      > .price {
        margin: 0.5rem 0;
        font-size: 0.9rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      min-width: 6rem;

      .image {
        height: 4rem;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
