.cartButtonContainer {
  position: fixed;
  bottom: 0;
  left: -1rem;
  right: -1rem;

  .cartButton {
    padding: 1rem;
    background-color: #ff5252;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 0.9rem;
      font-weight: 500;
      color: #fff;
    }

    svg {
      color: #fff;
      font-size: 1.1rem;
    }

    .viewCartContainer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .text {
        margin-right: 0.2rem;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #ff1f1f;
    }
  }
}
