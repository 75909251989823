.banner {
  width: 100%;
  height: 60vh;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  padding: 2rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .disclaimer {
    margin: 2rem 0;
    font-size: 0.7rem;
    font-weight: 400;
  }
}
