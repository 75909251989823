.userDetailsContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 2rem 0;

  .avatar {
    height: 4rem;
    width: 4rem;
    background-color: #f6f3eb;
    border-radius: 50%;
    margin: 0 0.5rem;
  }

  .details {
    margin-left: 0.5rem;

    .name {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 0;
      margin-top: 0;
    }
    .phoneNumber {
      margin-top: 0.2rem;
      margin-bottom: 0;
      font-size: 0.8rem;
    }
  }
}

.subHeading {
  font-size: 0.8rem;
  font-weight: 500;
  color: #696969;
  margin-top: 2rem;
}
