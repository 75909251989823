.errorContainer {
  padding: 1rem;

  .image {
    height: 15rem;
  }

  .title {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
  }

  .message {
    text-align: center;
  }
}
