.buttonContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 0.2rem;

  .count {
    margin: 0 0.3rem;
  }
}

.drawer {
  padding: 0.5rem 0;

  .header {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .button {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 992px) {
    padding: 0.5rem 1rem;
  }
}
