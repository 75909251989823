$width: calc(50% - 1.5rem);

.container {
  margin: 1rem 0;
  width: $width;

  .imageContainer {
    width: 100%;
    min-height: 5rem;
    background-color: #f6f3eb;
    border: 1px solid #f3e2b5;
    border-radius: 0.6rem;
    position: relative;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 0.45rem;
    }

    .dimmer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #8f8f8fbb;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .status {
        color: #fff;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  .labelContainer {
    flex: 1;

    .name {
      font-size: 0.9rem;
      color: #212121;
      margin: 0.4rem 0;
      font-weight: 700;
    }

    .description {
      font-size: 0.8rem;
      color: #000;
      margin-top: -0.1rem;
    }
  }

  &:hover {
    cursor: pointer;

    .imageContainer {
      box-shadow: inset 0px 0px 0px 2px #f3e2b5;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .imageContainer {
      box-shadow: none;
    }
  }
}
