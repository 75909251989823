.listButtonContainer {
  padding: 1rem 0;
  border-bottom: 1px solid #cacaca;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .label {
    font-weight: 400;
  }

  svg {
    color: #212121;
  }

  &:hover {
    cursor: pointer;

    .label,
    svg {
      color: #ff5252;
    }
  }
}
