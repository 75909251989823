.locationContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f3eb;
  margin: 1rem 0;
  border: 1px solid #f3e2b5;
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;

  .name {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0;
    margin: 0.4rem 0;
  }

  .address {
    font-size: 1.1rem;
    padding: 0;
    margin: 0.4rem 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.vendorContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.heading {
  > h2 {
    margin: 0;
  }

  > p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
