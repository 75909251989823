.listItemContainer {
  padding: 0.5rem 0;

  .label {
    font-weight: 500;
    font-size: 0.9rem;
    color: #696969;
    margin-bottom: 0;
    margin-top: 0;
  }

  .value {
    margin-top: 0.5rem;
  }
}
