.subHeadingContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .subHeading {
    font-weight: 500;
  }
}

.orderContainer {
  .information {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .referenceId {
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 0;
      color: #deaa0c;
    }

    .price {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0.5rem 0 0 0;
    }

    .status {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0.5rem 0 0 0;
    }

    .orderDate {
      font-size: 0.8rem;
      margin: 0.5rem 0 0 0;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
}
