.makePaymentButton {
  margin-bottom: 20rem;
}

.informationContainer {
  .header {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }

  .vendorName {
    margin-top: 0;
    font-size: 0.9rem;
  }
}

.cartDishCard {
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 0;

  .informationContainer {
    flex: 1;
    padding: 0 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .left {
      > .name {
        margin: 0 0 0.3rem 0;
        font-weight: 500;
        font-size: 1rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      min-width: 6rem;

      > .price {
        margin: 0.5rem 0;
        font-size: 0.9rem;
      }
    }
  }
}

.billDetailsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem 0;

  .label {
    font-weight: 500;
    font-size: 1.2rem;
  }

  .price {
    font-weight: 500;
    font-size: 1.4rem;
  }
}

.purseModalContainer {
  .totalAmountContainer {
    margin: 0.5rem 0;
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.1rem;

    span {
      font-weight: 500;
    }
  }

  .paymentOption {
    border: 1px solid #eee;
    border-radius: 0.4rem;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    margin: 1rem 0;
    padding: 0.3rem;
    opacity: 0.8;

    .content {
      .header {
        margin-top: 0.6rem;
        margin-bottom: 0;
      }

      .description {
        margin-top: 0.6rem;
        margin-bottom: 0;
        font-size: 0.9rem;
      }
    }

    &.selected {
      border: 1px solid #ff5252;
      opacity: 1;

      .header {
        font-weight: 500;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .payableAmountContainer {
    margin: 0.5rem 0;
    align-items: center;
    padding: 0.5rem 0;
    border-radius: 0.1rem;

    span {
      font-weight: 500;
    }
  }
}
