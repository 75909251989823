.title {
  margin: 0.5rem 0;

  .titleContainer {
    margin: 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .navigateBack {
      margin-left: -1.1rem;
      svg {
        color: #000;
      }
    }

    .name {
      margin-left: 0.5rem;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}
