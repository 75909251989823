.contentContainer {
  margin-bottom: 15rem;
}

.statusContainer {
  > .header {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
  }

  > .referenceId {
    margin-top: 0.5rem;
    text-align: center;
  }

  > .message {
    text-align: center;
  }

  > .statusImage {
    height: 10rem;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  .image {
    height: 15rem;
  }
}

.summaryContainer {
  .subHeading {
    font-weight: 500;
  }

  .vendorName {
    font-size: 0.9rem;
    margin-bottom: 0;
    color: #ff5252;
  }

  .orderTime {
    margin-top: 0.4rem;
    font-size: 0.8rem;
  }
}

.dishCard {
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 0;

  .informationContainer {
    flex: 1;
    padding: 0 0 0 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .left {
      > .name {
        margin: 0 0 0.3rem 0;
        font-weight: 500;
        font-size: 1rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      min-width: 6rem;

      > .price {
        margin: 0.5rem 0;
        font-size: 0.9rem;
      }
    }
  }
}

.totalContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  > .label,
  .value {
    font-weight: 500;
    font-size: 0.9rem;
    margin-left: 1rem;
  }
}

.helpButton {
  margin-top: 1rem;
}

.feedbackContainer {
  > .starContainer {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  > .comments {
    text-align: center;
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  > .inputContainer {
    margin: 1rem 0;
  }

  > .submitButton {
    display: flex;
    justify-content: center;
  }
}
