.notesButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1rem 0;

  cursor: pointer;
}

.inputField {
  margin: 1rem 0;
}
